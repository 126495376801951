import { render, staticRenderFns } from "./InputFormGroup.vue?vue&type=template&id=6a0431a5&scoped=true&"
import script from "./InputFormGroup.vue?vue&type=script&lang=js&"
export * from "./InputFormGroup.vue?vue&type=script&lang=js&"
import style0 from "./InputFormGroup.vue?vue&type=style&index=0&id=6a0431a5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0431a5",
  null
  
)

export default component.exports