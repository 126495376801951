<template>
  <div v-if="apartment && details" class="card rounded-0">
    <div class="card-body p-0 min-h-100px">
      <div class="row justify-content-center py-8 px-8 py-md-27 px-md-0">
        <div class="col-md-10">
          <div class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
            <h1 class="display-4 font-weight-boldest text-uppercase mb-10">{{ $t('title.payment_details') }}</h1>
            <div class="d-flex flex-column align-items-md-end px-0">
<!--              <span class="d-flex flex-column align-items-md-end opacity-70">-->
<!--                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</span>-->
<!--                <span>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span>-->
<!--              </span>-->
            </div>
          </div>
          <div class="border-bottom w-100"></div>
          <div class="d-flex justify-content-between pt-6">
            <div class="d-flex flex-column flex-root">
              <span class="font-weight-bolder text-uppercase mb-5">{{ $t('label.apartment') }}</span>
              <span class="opacity-70">{{ apartment.apartment }}</span>
            </div>
            <div class="d-flex flex-column flex-root">
              <span class="font-weight-bolder text-uppercase mb-5">{{ $t('label.type') }}</span>
              <span class="opacity-70">{{ $t(`label.${type}`) }}</span>
            </div>
            <div class="d-flex flex-column flex-root">
              <span class="font-weight-bolder text-uppercase mb-5">{{ $t('label.sum') }}</span>
              <div class="d-flex">
                <span v-if="!!details.debt">{{ form.sum }}</span>
                <InputFormGroup v-else class="price-input mr-1" v-model="form.sum"/>
                <span class="ml-1">{{ details.balance.currency_symbol }}</span>
                <span v-if="form.type === 'ucc'" class="ml-1">({{ `+${commissionRate}% VISA` }})</span>
              </div>
            </div>
            <div class="d-flex flex-column flex-root">
              <span class="font-weight-bolder text-uppercase mb-2">{{ $t('label.payment_type') }}</span>

              <b-form-group>
                <b-form-radio-group id="radio-group-2" v-model="form.type" class="mt-3">
                  <b-form-radio v-for="type in paymentTypes" :key="type.id" :value="type.id">
                    <div v-if="type.id === 'ucc'" class="mt-n1">
                      <span class="fab fa-cc-visa font-size-h2 mr-2"></span>
                      <span class="fab fa-cc-mastercard font-size-h2"></span>
                    </div>
                    <span v-else>{{ type.label }}</span>
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-light-success mt-10" @click="pay()">
              {{ $t('btn.continue_to_checkout') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import InputFormGroup from '@/components/elements/form-groups/InputFormGroup';
import MultiselectFormGroup from '@/components/elements/form-groups/MultiselectFormGroup';

const SERVICES_ID = 41;
const ELECTRICITY_ID = 11;
const WATER_ID = 12;
const INTERNET_TV_ID = 21;

export default {
  components: {
    InputFormGroup,
    MultiselectFormGroup
  },
  data: () => ({
    apartmentId: null,
    type: null,
    balance: null,
    paymentUrl: null,
    commissionRate: 2,
    form: {
      sum: 0,
      type: 'ucc'
    }
  }),
  beforeMount() {
    this.apartmentId = this.$route.params.apartment_id;
    this.type = this.$route.params.type;
    this.$store.dispatch('apartmentsStore/GET_DETAILS', this.apartmentId);
  },
  computed: {
    ...mapGetters({
      apartment: 'apartmentsStore/APARTMENT'
    }),
    paymentTypes() {
      return [{id: 'ucc', label: this.$t('label.visa_mastercard')}, {id: 'invoice', label: this.$t('label.invoice')}];
    },
    details() {
      let details = {};
      switch (this.type) {
        case 'electricity':
          details = this.apartment.electricity;
          break;
        case 'apartment_service':
          details = this.apartment.service;
          break;
        case 'water':
          details = this.apartment.water;
          break;
        case 'internet_tv':
          details = this.apartment.internetTv;
          break;
      }

      return details;
    },
    serviceId() {
      let id = null;
      switch (this.type) {
        case 'apartment_service':
          id = SERVICES_ID;
          break;
        case 'electricity':
          id = ELECTRICITY_ID;
          break;
        case 'water':
          id = WATER_ID;
          break;
        case 'internet_tv':
          id = INTERNET_TV_ID;
          break;
      }

      return id;
    }
  },
  watch: {
    details(data) {
      if (data && data.debt) {
        let balance = data.balance?.value;
        this.form.sum = balance < 0 ? balance * -1 : balance;
      }
    }
  },
  methods: {
    pay() {
      this.$store.dispatch('apartmentsStore/PAY', {
        epCode: this.apartment.epCode,
        type: this.form.type,
        serviceId: this.serviceId,
        sum: this.form.sum
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.price-input::v-deep {
  width: 75px;
  margin: -10px 0 0;

  &.readonly {
    input {
      margin-left: -12px;
      border: none;
    }
  }
}
</style>
