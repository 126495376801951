<template>
  <div class="form-group">
    <div v-if="label"
         class="form-group__label-wrapper"
         :class="{ required }"
    >
      <label class="form-group__label">{{ label }}</label>
      <i
          v-if="inputType === 'password'"
          class="far ml-3"
          :class="showPass ? 'fa-eye' : 'fa-eye-slash'"
          @click="showPass = !showPass"
      ></i>
    </div>
<!--    :class="{error: withError && !value}"-->
    <b-form-input
        class="app-input"
        :class="{error: withError}"
        :value="value"
        :disabled="disabled"
        :readonly="readOnly"
        :placeholder="placeholder"
        :ref="inputRef"
        trim
        :type="showPass ? 'text' : inputType"
        @input="$emit('input', $event)"
        @keydown="onKeydown"
    ></b-form-input>
    <span v-if="helpMessage" class="form-text text-muted">{{ helpMessage }}</span>
<!--    <p v-if="withError && !value" class="error-message">{{ errorMessage }}</p>-->
    <p v-if="withError" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import i18n from '@/core/plugins/vue-i18n';

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: String,
      default: 'text'
    },
    inputRef: {
      type: String,
      default: ''
    },
    singleValue: {
      type: Boolean,
      default: false
    },
    numeric: {
      type: Boolean,
      default: false
    },
    withError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: i18n.t('error.field_is_required')
    },
    helpMessage: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    showPass: false
  }),
  methods: {
    isNumber(evt) {
      const key = evt.key;
      // Allow only backspace, delete, arrow keys, and numbers
      if (key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight' || /^[0-9]$/.test(key)) {
        return true;
      } else {
        evt.preventDefault();
        return false;
      }
    },
    onKeydown(e) {
      if (this.numeric) {
        this.isNumber(e);
      }

      if (this.singleValue) {
        // Set last character instead of previous one
        if (e.target.value.length === 1) {
          if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') return;
          e.preventDefault();
          if (/^[0-9]$/.test(e.key)) {
            e.target.value = e.key;
            this.$emit('input', e.target.value);
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}

.form-group__label-wrapper {
  &.required {
    position: relative;
    display: inline-block;

    :after {
      content: "*";
      position: absolute;
      color: #f64e60;
      font-weight: bold;
      right: -10px;
    }
  }

  &.password {
    display: flex;
    align-items: center;
  }
}

.flaticon-eye {
  margin-top: -3px;
  cursor: pointer;
  opacity: 0.5;

  &.active {
    opacity: 1;
  }
}

.error-message {
  position: absolute;
  bottom: -32px;
  left: 0;
  font-size: 0.9rem;
  color: #f64e60;
}

.app-input {
  height: 38px;
}

.app-input.error {
  border-color: #f64e60;
}
</style>
